import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import CompareNumbers from './CompareNumbers';
import RemoveDuplicates from './RemoveDuplicates';
import ExtractData from './ExtractData';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
              <Link to="/">Сравнение номеров</Link>
            </li>
            <li>
              <Link to="/remove-duplicates">Удаление дубликатов</Link>
            </li>
            <li>
              <Link to="/extract-data">Extract Data</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<CompareNumbers />} />
          <Route path="/remove-duplicates" element={<RemoveDuplicates />} />
          <Route path="/extract-data" element={<ExtractData />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
