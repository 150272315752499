import React, { useState } from 'react';

function ExtractData() {
    const [numbers, setNumbers] = useState('');
    const [file, setFile] = useState(null);
    const [result, setResult] = useState([]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleExtractData = async () => {
        const uniqueNumbers = [...new Set(numbers.split('\n'))];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('uniqueNumbers', JSON.stringify(uniqueNumbers));

        try {
            const response = await fetch('/extract-data', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setResult(data.result);
        } catch (error) {
            console.error('Error during data extraction:', error);
        }
    };

    return (
        <div>
            <h1>Extract Data</h1>
            <p>Автоматически вытаскиваются номера из выгрузки бпм</p> <p>Вставлять номера ЦОКов по 1 на строку, если ЭОМов нету, ЦОК будет пропущен</p>
            <div className="input-container">
                <textarea
                    value={numbers}
                    onChange={(e) => setNumbers(e.target.value)}
                    placeholder="Enter numbers (one per line)"
                />
                <input type="file" onChange={handleFileChange} />
            </div>
            <button onClick={handleExtractData}>Extract Data</button>
            {result.length > 0 && (
                <div className="result">
                    <h2>Номера для обновления Base:</h2>
                    <table>
                        <tbody>
                            {result.map((item, index) => {
                                const [num, value] = item.split('\t');
                                return (
                                    <tr key={index}>
                                        <td>{num}</td>
                                        <td>{value}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default ExtractData;
