import React, { useState } from 'react';

function RemoveDuplicates() {
  const [numbers, setNumbers] = useState('');
  const [uniqueNumbers, setUniqueNumbers] = useState([]);

  const handleRemoveDuplicates = () => {
    const numberArray = numbers.split('\n');
    const uniqueSet = new Set(numberArray);
    setUniqueNumbers([...uniqueSet]);
  };

  return (
    <div>
      <h1>Удаление дубликатов</h1>
      <div className="input-container">
        <textarea
          value={numbers}
          onChange={(e) => setNumbers(e.target.value)}
          placeholder="Enter numbers (one per line)"
        />
      </div>
      <button onClick={handleRemoveDuplicates}>Remove Duplicates</button>
      {uniqueNumbers.length > 0 && (
        <div className="result">
          <h2>Уникальные номера:</h2>
          <ul>
            {uniqueNumbers.map((num, index) => (
              <li key={index}>{num}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default RemoveDuplicates;
