import React, { useState } from 'react';

function CompareNumbers() {
    const [set1, setSet1] = useState('');
    const [set2, setSet2] = useState('');
    const [uniqueSet2, setUniqueSet2] = useState([]);

    const handleCompare = async () => {
        try {
            const response = await fetch('/compare', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ set1: set1.split('\n'), set2: set2.split('\n') }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setUniqueSet2(data.uniqueSet2);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    return (
        <div>
            <h1>Сравнение номеров</h1>
            <div className="input-container">
                <p>Меньше (без дубликатов)</p>
                <textarea
                    value={set1}
                    onChange={(e) => setSet1(e.target.value)}
                    placeholder="Enter first set of numbers (one per line)"
                /><br />
                <p>Больше (без дубликатов)</p>
                <textarea
                    value={set2}
                    onChange={(e) => setSet2(e.target.value)}
                    placeholder="Enter second set of numbers (one per line)"
                />
            </div>
            <button onClick={handleCompare}>Compare</button>
            {uniqueSet2.length > 0 && (
                <div className="result">
                    <h2>Найденное</h2>
                    <ul>
                        {uniqueSet2.map((num, index) => (
                            <li key={index}>{num}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default CompareNumbers;
